// import React, { useContext,useState,useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import jwt_decode from 'jwt-decode';
// import { AuthContext } from '../context/AuthContext';
// import { Link } from 'react-router-dom';
// import logo from './logo.png';
// // import userImage from './R.png'; // Change this to the correct path of the user's image
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css'




// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);

//   let username = '';

//   if (token) {
//     const decoded = jwt_decode(token);
//     username = decoded.username;
    
//   }

//   // Function to close the menu
//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   // Close the menu when the window is resized to a larger screen
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);


//   return (
//     <h4>
//      <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav  className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar" >
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//              <img style={{ width: '180px', padding: '6px' }} src={logo} alt="" />
//           </a>
          
//                     <button
//             className="navbar-toggler"
//             type="button"
//             data-bs-toggle="collapse"  
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul  className="navbar-nav">
//               {token === null && (

//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>

//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/quiz" onClick={closeMenu}>
//                       Take a Quiz
//                     </Link>
//                   </li> */}

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
                    
//                   </li>
                  
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/notice" onClick={closeMenu}>
//                       Notice
//                     </Link>
//                   </li> */}

                
//                 </>
//               )}

//               {token !== null && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/dashboard" onClick={closeMenu}>
//                       Dashboard
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/quiz" onClick={closeMenu}>
//                       Take a Quiz
//                     </Link>
//                   </li> */}
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/rquiz" onClick={closeMenu}>
//                       Take a RQuiz
//                     </Link>
//                   </li> */}
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/sallybus" onClick={closeMenu}>
//                       Syllabus
//                     </Link>
//                   </li> */}
//                   <li className="nav-item">
//                   <button className="nav-link" onClick={() => {
//                                                       logoutUser();
//                                                       closeMenu();
//                                                     }} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
//                              Logout
//                     </button>
//                   </li>
            
            
//                 </>



                
//               )}


//             </ul>

//             <div>
//                   <li className="nav-item mr-5 ml-auto d-flex align-items-center justify-content-end user-greeting" style={{font:'12px'}}> 
//                     <span>Hello, {username}!</span>
//                     {/* <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     /> */}
//                   </li>
//                   </div>
//           </div>
     
//         </div>
        
//       </nav>
//     </div>
//     </h4>
//   );
// }

// export default Navbar;




// import React, { useContext, useState, useEffect } from 'react';
// import { AuthContext } from '../context/AuthContext';
// import { Link } from 'react-router-dom';
// import logo from './logo.png';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css';

// function Navbar() {
//   const {  logout, isAuthenticated } = useContext(AuthContext);
//   // const { user, logout, isAuthenticated } = useContext(AuthContext);
//   const [menuOpen, setMenuOpen] = useState(false);

//   // Function to close the menu
//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   // Close the menu when the window is resized to a larger screen
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   return (
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar">
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//             <img style={{ width: '180px', padding: '6px' }} src={logo} alt="" />
//           </a>
          
//           <button
//             className="navbar-toggler"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul className="navbar-nav">
//               {!isAuthenticated() && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
//                   </li>
//                 </>
//               )}

//               {isAuthenticated() && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   {/* <li className="nav-item">        
//                        <Link className="nav-link" to="/dashboard" onClick={closeMenu}>                     Dashboard
//                   </Link>
//                 </li>  */}
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <button
//                       className="nav-link"
//                       onClick={() => {
//                         logout();
//                         closeMenu(); // Close the menu after logging out
//                       }}
//                       style={{ cursor: 'pointer', border: 'none', background: 'none' }}
//                     >
//                       Logout
//                     </button>
//                   </li>
//                 </>
//               )}
//             </ul>

//             {isAuthenticated() && (
//               <div className="ml-auto d-flex align-items-center justify-content-end user-greeting">
//                 <li className="nav-item mr-5" style={{ fontSize: '12px' }}>
//                   {/* <span>Hello, {user?.username || 'User'}!</span>
//          */}
//                 </li>
//               </div>
//             )}
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// }

// export default Navbar;


// //navbar for SO-PSC

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import React, { useContext,useState,useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import jwt_decode from 'jwt-decode';
// import  AuthContext  from '../context/AuthContext';
// import { Link } from 'react-router-dom';
// import logo from './logo.png';
// import userImage from './R.png'; // Change this to the correct path of the user's image
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css'




// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   let username = '';
  


//   if (token) {
//     const decoded = jwt_decode(token);
//     username = decoded.username;
    
//   }

//   // Function to close the menu
//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   // Close the menu when the window is resized to a larger screen
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);


//   return (
//     <h4>
//      <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav  className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar" >
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//              <img style={{ width: '120px', padding: '6px' }} src={logo} alt="" />
//           </a>
          
//                     <button
//             className="navbar-toggler justify-content-end"
//             type="button"
//             data-bs-toggle="collapse"  
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul  className="navbar-nav">
//               {token === null && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/cun" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
                    
//                   </li>
                  
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/notice" onClick={closeMenu}>
//                       Notice
//                     </Link>
//                   </li> */}

                
//                 </>
//               )}

//               {token !== null && (
//                 <>
//                                   <li className="nav-item">
//                     <Link className="nav-link" to="/" onClick={closeMenu}>
//                       Home
//                     </Link>
//                   </li> 
//                                   <li className="nav-item">
//                     <Link className="nav-link" to="/course/:courseName" onClick={closeMenu}>
//                       Dashboard
//                     </Link>
//                   </li> 
//               {/* <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
              
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/rquiz" onClick={closeMenu}>
//                    License practice exam
//                     </Link>
//                   </li> */}
//                                        <li className="nav-item">
//                     <Link className="nav-link" to="/profile" onClick={closeMenu}>
//                       Profile
//                     </Link>
//                   </li> 



//                   <li className="nav-item justify-content-end ml-auto">
//                   <button className="nav-link" onClick={() => {
//                                                       logoutUser();
//                                                       closeMenu();
//                                                     }} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
//                              Logout
//                     </button>
//                   </li>
//                   <li className="nav-item ml-auto d-flex align-items-center text-right user-greeting"> 
//                     <span>Hello,  {username} !</span>
//                     <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>

//                            </>
//               )}
//             </ul>
//           </div>
//         </div>
        
//       </nav>
//     </div>
//     </h4>
//   );
// }

// export default Navbar;



// //navbar for nec 



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import React, { useContext,useState,useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import jwt_decode from 'jwt-decode';
// import  AuthContext  from '../context/AuthContext';
// import { Link } from 'react-router-dom';
// import logo from './logo.png';
// import userImage from './R.png'; // Change this to the correct path of the user's image
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css'




// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   let username = '';
  


//   if (token) {
//     const decoded = jwt_decode(token);
//     username = decoded.username;
    
//   }

//   // Function to close the menu
//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   // Close the menu when the window is resized to a larger screen
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);


//   return (
//     <h4>
//      <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav  className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar" >
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//              <img style={{ width: '120px', padding: '6px' }} src={logo} alt="" />
//           </a>
          
//                     <button
//             className="navbar-toggler justify-content-end"
//             type="button"
//             data-bs-toggle="collapse"  
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul  className="navbar-nav">
//               {token === null && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/cun" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
                    
//                   </li>
                  
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/notice" onClick={closeMenu}>
//                       Notice
//                     </Link>
//                   </li> */}

                
//                 </>
//               )}

//               {token !== null && (
//                 <>
//                                   <li className="nav-item">
//                     <Link className="nav-link" to="/" onClick={closeMenu}>
//                       Home
//                     </Link>
//                   </li> 
//                                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/course/:courseName" onClick={closeMenu}>
//                       Dashboard
//                     </Link>
//                   </li>  */}
//               <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
              
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/rquiz" onClick={closeMenu}>
//                    License practice exam
//                     </Link>
//                   </li>
//                                        {/* <li className="nav-item">
//                     <Link className="nav-link" to="/profile" onClick={closeMenu}>
//                       Profile
//                     </Link>
//                   </li>  */}



//                   <li className="nav-item justify-content-end ml-auto">
//                   <button className="nav-link" onClick={() => {
//                                                       logoutUser();
//                                                       closeMenu();
//                                                     }} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
//                              Logout
//                     </button>
//                   </li>
//                   <li className="nav-item ml-auto d-flex align-items-center text-right user-greeting"> 
//                     <span>Hello,  {username} !</span>
//                     <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>

//                            </>
//               )}
//             </ul>
//           </div>
//         </div>
        
//       </nav>
//     </div>
//     </h4>
//   );
// }

// export default Navbar;


// import React, { useContext, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import jwt_decode from 'jwt-decode';
// import AuthContext from '../context/AuthContext';
// import logo from './logo.png';
// import userImage from './R.png';
// import './navbar.css';

// function Navbar({ courseCode }) {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   let username = '';

//   if (token) {
//     const decoded = jwt_decode(token);
//     username = decoded.username;
//   }

//   const closeMenu = () => setMenuOpen(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   // Define course-specific links
//   const links = {
//     'SO-PSC': [
//       { path: '/', label: 'Home' },
//       { path: '/course/:courseName', label: 'Dashboard' },
//       { path: '/profile', label: 'Profile' },
//     ],
//     'AGeE': [
//       { path: '/', label: 'Home' },
//       { path: '/chapterlist', label: 'Chapter Wise' },
//       { path: '/rquiz', label: 'License Practice Exam' },
//     ],
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white">
//       <div className="container-fluid">
//         <a className="navbar-brand" href="/">
//           <img style={{ width: '120px', padding: '6px' }} src={logo} alt="Logo" />
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarNav"
//           aria-controls="navbarNav"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//           onClick={() => setMenuOpen(!menuOpen)}
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//           <ul className="navbar-nav">
//             {token === null
//               ? (
//                 <>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/" onClick={closeMenu}>
//                       Home
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
//                   </li>
//                 </>
//               )
//               : (
//                 <>
//                   {links['SO-PSC']?.map((link, index) => (
//                     <li className="nav-item" key={index}>
//                       <Link className="nav-link" to={link.path} onClick={closeMenu}>
//                         {link.label}
//                       </Link>
//                     </li>
//                   ))}
//                   <li className="nav-item">
//                     <button
//                       className="nav-link"
//                       onClick={() => {
//                         logoutUser();
//                         closeMenu();
//                       }}
//                       style={{ cursor: 'pointer', border: 'none', background: 'none' }}
//                     >
//                       Logout
//                     </button>
//                   </li>
//                   <li className="nav-item d-flex align-items-center">
//                     <span>Hello, {username}!</span>
//                     <img
//                       src={userImage}
//                       alt="User Avatar"
//                       className="rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>
//                 </>
//               )}
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;


// import React, { useContext, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// import AuthContext from '../context/AuthContext';
// import logo from './logo.png';
// import userImage from './R.png'; // Adjust path accordingly
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css';

// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [userDetails, setUserDetails] = useState(null);

//   useEffect(() => {
//     // Retrieve user details from localStorage once (only on mount)
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       setUserDetails(JSON.parse(storedUserDetails));
//     }
//   }, []); // Runs only once on mount

//   // Extract the courseCode if userDetails is available
//   const courseCode = userDetails?.course?.courseCode;

//   const links = {
//     'SO-PSC': [
//       { path: '/', label: 'Home' },
//       { path: '/course/:courseName', label: 'Dashboard' },
//       { path: '/profile', label: 'Profile' },
//     ],
//     'AGeE': [
//       { path: '/', label: 'Home' },
//       { path: '/chapterlist', label: 'Chapter Wise' },
//       { path: '/rquiz', label: 'License Practice Exam' },
//       { path: '/profile', label: 'Profile' },
//     ],
//   };

//   const closeMenu = () => setMenuOpen(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) closeMenu();
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar">
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//             <img style={{ width: '120px', padding: '6px' }} src={logo} alt="Logo" />
//           </a>
//           <button
//             className="navbar-toggler justify-content-end"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul className="navbar-nav">
//               {token === null ? (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
//                   </li>
//                 </>
//               ) : (
//                 <>
//                   {links[courseCode]?.map((link, index) => (
//                     <li className="nav-item" key={index}>
//                       <Link className="nav-link" to={link.path} onClick={closeMenu}>
//                         {link.label}
//                       </Link>
//                     </li>
//                   ))}
//                   <li className="nav-item justify-content-end ml-auto">
//                     <button
//                       className="nav-link"
//                       onClick={() => {
//                         logoutUser();
//                         closeMenu();
//                       }}
//                       style={{ cursor: 'pointer', border: 'none', background: 'none' }}
//                     >
//                       Logout
//                     </button>
//                   </li>
//                   <li className="nav-item ml-auto d-flex align-items-center text-right user-greeting">
//                     <span>Hello, {userDetails?.first_name}!</span>
//                     <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>
//                 </>
//               )}
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// }

// export default Navbar;

//above is the working Navbar






import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import AuthContext from '../context/AuthContext';
import logo from './logo.png';
import userImage from './R.png'; // Adjust path accordingly
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './navbar.css';

function Navbar() {
  const { logoutUser } = useContext(AuthContext);
  const token = localStorage.getItem('authTokens');
  const [menuOpen, setMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);
      setUserDetails(parsedDetails);

      // Validity Expiry Check
      const validityDate = new Date(parsedDetails?.validity || ''); // Parse validity date
      const today = new Date();
      if (validityDate < today) {
        setIsExpired(true); // Set the flag if validity has expired
      }
    }
  }, []);

  const courseCode = userDetails?.course?.courseCode;

  const links = {
    'SO-PSC': [
      { path: '/', label: 'Home' },
      { path: '/course/:courseName', label: 'Dashboard' },
      { path: '/profile', label: 'Profile' },
    ],
    'AGeE': [
      { path: '/', label: 'Home' },
      { path: '/chapterlist', label: 'Chapter Wise' },
      { path: '/rquiz', label: 'License Practice Exam' },
      { path: '/profile', label: 'Profile' },
    ],
  };

  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) closeMenu();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img style={{ width: '120px', padding: '6px' }} src={logo} alt="Logo" />
          </a>
          <button
            className="navbar-toggler justify-content-end"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
            <ul className="navbar-nav">
              {token === null ? (
                <>
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/login" onClick={closeMenu}>
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/register" onClick={closeMenu}>
                      Register
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  {links[courseCode]?.map((link, index) => (
                    <li className="nav-item" key={index}>
                      <Link
                        className={`nav-link${isExpired && link.label !== 'Home' && link.label !== 'Profile' ? ' disabled' : ''}`}
                        to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
                        onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
                      >
                        {link.label}
                      </Link>
                    </li>
                  ))}
                  <li className="nav-item justify-content-end ml-auto">
                    <button
                      className="nav-link"
                      onClick={() => {
                        logoutUser();
                        closeMenu();
                      }}
                      style={{ cursor: 'pointer', border: 'none', background: 'none' }}
                    >
                      Logout
                    </button>
                  </li>
                  <li className="nav-item ml-auto d-flex align-items-center text-right user-greeting">
                    <span>Hello, {userDetails?.first_name}!</span>
                    <img
                      src={userImage}
                      alt="User Profile"
                      className="user-avatar rounded-circle ml-2"
                      style={{ width: '40px', height: '40px' }}
                    />
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;



