// import React, { useContext,useState } from 'react'
// import { Link } from 'react-router-dom'
// import AuthContext from '../context/AuthContext'
// import ForgotPassword from './ForgetPasswordPage'
// import Modal from 'react-modal';
// import sin from './images/sin.png'



// function Loginpage() {

//   const {loginUser} = useContext(AuthContext)
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   const openModal = () => {
//     setModalIsOpen(true);
//   };
  
//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   const handleSubmit = e => {
//     e.preventDefault()
//     const email = e.target.email.value
//     const password = e.target.password.value

//     email.length > 0 && loginUser(email, password)

//     console.log(email)
//     console.log(password)
   
//   }

//   return (
//     <div>
//       <>
//   <section className="vh-100" style={{ backgroundColor: "#7cb474" }}>
//     <div className="container py-5 h-100">
//       <div className="row d-flex justify-content-center align-items-center h-100">
//         <div className="col col-xl-10">
//           <div className="card" style={{ borderRadius: "1rem" }}>
//             <div className="row g-0">
//               <div className="col-md-6 col-lg-5 d-none d-md-block">
//                 <img
//                   src= {sin}
//                   alt="login form"
//                   className="img-fluid"
//                   style={{ borderRadius: "1rem 0 0 1rem" }}
//                 />
//               </div>
//               <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                 <div className="card-body p-4 p-lg-5 text-black">
//                   <form onSubmit={handleSubmit}>
//                     <div className="d-flex align-items-center mb-3 pb-1">
//                       <i
//                         className="fas fa-cubes fa-2x me-3"
//                         style={{ color: "#ff6219" }}
//                       />
//                       <div className="d-flex align-items-center mb-3 pb-1">
//                         <i
//                           className="fas fa-cubes fa-2x me-3"
//                           style={{ color: "#ff6219" }}
//                         />
//                         <span className="h2 fw-bold mb-0">Welcome back 👋</span>
//                       </div>
//                     </div>
//                     <h5
//                       className="fw-normal mb-3 pb-3"
//                       style={{ letterSpacing: 1 }}
//                     >
//                       Sign into your account
//                     </h5>
//                     <div className="form-outline mb-4">
//                       <input
//                         type="email"
//                         id="form2Example17"
//                         className="form-control form-control-lg"
//                         name='email'
//                       />
//                       <label className="form-label" htmlFor="form2Example17">
//                         Email address
//                       </label>
//                     </div>
//                     <div className="form-outline mb-4">
//                       <input
//                         type="password"
//                         id="form2Example27"
//                         className="form-control form-control-lg"
//                         name='password'
//                       />
//                       <label className="form-label" htmlFor="form2Example27">
//                         Password
//                       </label>
//                     </div>
//                     <div className="pt-1 mb-4">
//                       <button
//                         className="btn btn-dark btn-lg btn-block"
//                         type="submit"
//                       >
//                         Login
//                       </button>
//                     </div>
//                       <a className="small text-muted" href="#!" onClick={openModal}>
//                          Forgot password?
//                       </a>

//                            <Modal
//                             isOpen={modalIsOpen}
//                             onRequestClose={closeModal}
//                             contentLabel="Forgot Password Modal"
//                             >
//                             <ForgotPassword />
//                             <button onClick={closeModal}>Close</button>
//                           </Modal>

//                     <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
//                       Don't have an account?{" "}
//                       <Link to="/register" style={{ color: "#393f81" }}>
//                         Register Now 
//                       </Link>
//                     </p>
//                     <a href="#!" className="small text-muted">
//                       Terms of use.
//                     </a>
//                     <a href="#!" className="small text-muted">
//                       Privacy policy
//                     </a>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
//   <footer className="bg-light text-center text-lg-start">
//     <div
//       className="text-center p-3"
//       style={{ backgroundColor: '#7cb474' }}
//     >
//       © Geoneer Geospatial Services
//       <a className="text-dark" href="https://geoneer.com.np/">
//         Geoneer
//       </a>
//     </div>
//   </footer>
// </>

//     </div>
//   )
// }

// export default Loginpage

import React, { useContext,useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import ForgotPassword from './ForgetPasswordPage'
import Modal from 'react-modal';
import sin from './images/sin.png'



function Loginpage() {

  const {loginUser} = useContext(AuthContext)
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault()
    const email = e.target.email.value
    const password = e.target.password.value

    email.length > 0 && loginUser(email, password)

    console.log(email)
    console.log(password)
   
  }

  return (
    <div>
      <>
  <section className="vh-100" style={{ backgroundColor: "#7cb474" }}>
    <div className="container py-5 h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col col-xl-10">
          <div className="card" style={{ borderRadius: "1rem" }}>
            <div className="row g-0">
              <div className="col-md-6 col-lg-5 d-none d-md-block">
                <img
                  src= {sin}
                  alt="login form"
                  className="img-fluid"
                  style={{ borderRadius: "1rem 0 0 1rem" }}
                />
              </div>
              <div className="col-md-6 col-lg-7 d-flex align-items-center">
                <div className="card-body p-4 p-lg-5 text-black">
                  <form onSubmit={handleSubmit}>
                    <div className="d-flex align-items-center mb-3 pb-1">
                      <i
                        className="fas fa-cubes fa-2x me-3"
                        style={{ color: "#ff6219" }}
                      />
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <i
                          className="fas fa-cubes fa-2x me-3"
                          style={{ color: "#ff6219" }}
                        />
                        <span className="h2 fw-bold mb-0">Welcome back 👋</span>
                      </div>
                    </div>
                    <h5
                      className="fw-normal mb-3 pb-3"
                      style={{ letterSpacing: 1 }}
                    >
                      Sign into your account
                    </h5>
                    <div className="form-outline mb-4">
                      <input
                        type="email"
                        id="form2Example17"
                        className="form-control form-control-lg"
                        name='email'
                      />
                      <label className="form-label" htmlFor="form2Example17">
                        Email address
                      </label>
                    </div>
                    <div className="form-outline mb-4">
                      <input
                        type="password"
                        id="form2Example27"
                        className="form-control form-control-lg"
                        name='password'
                      />
                      <label className="form-label" htmlFor="form2Example27">
                        Password
                      </label>
                    </div>
                    <div className="pt-1 mb-4">
                      <button
                        className="btn btn-dark btn-lg btn-block"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                      <a className="small text-muted" href="#!" onClick={openModal}>
                         Forgot password?
                      </a>

                           <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Forgot Password Modal"
                            >
                            <ForgotPassword />
                            <button onClick={closeModal}>Close</button>
                          </Modal>

                    <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                      Don't have an account?{" "}
                      <Link to="/register" style={{ color: "#393f81" }}>
                        Register Now 
                      </Link>
                    </p>
                    <a href="#!" className="small text-muted">
                      Terms of use.
                    </a>
                    <a href="#!" className="small text-muted">
                      Privacy policy
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer className="bg-light text-center text-lg-start">
    <div
      className="text-center p-3"
      style={{ backgroundColor: '#7cb474' }}
    >
      © Geoneer Geospatial Services
      <a className="text-dark" href="https://geoneer.com.np/">
        Geoneer
      </a>
    </div>
  </footer>
</>

    </div>
  )
}

export default Loginpage




// import React, { useContext, useState } from 'react'
// import { Link } from 'react-router-dom'
// import AuthContext from '../context/AuthContext'
// import ForgotPassword from './ForgetPasswordPage'
// import Modal from 'react-modal'
// import sin from './images/sin.png'

// function Loginpage() {
//   const { loginUser } = useContext(AuthContext)
//   const [modalIsOpen, setModalIsOpen] = useState(false)
//   const [email, setEmail] = useState('')
//   const [password, setPassword] = useState('')
//   const [errorMessage, setErrorMessage] = useState('')

//   const openModal = () => {
//     setModalIsOpen(true)
//   }

//   const closeModal = () => {
//     setModalIsOpen(false)
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault()

//     if (!email || !password) {
//       setErrorMessage('Please fill in both fields')
//       return
//     }

//     try {
//       await loginUser(email, password)
//     } catch (error) {
//       setErrorMessage('An error occurred during login. Please try again.')
//       console.error("Login error:", error)
//     }
//   }

//   return (
//     <div>
//       <section className="vh-100" style={{ backgroundColor: "#7cb474" }}>
//         <div className="container py-5 h-100">
//           <div className="row d-flex justify-content-center align-items-center h-100">
//             <div className="col col-xl-10">
//               <div className="card" style={{ borderRadius: "1rem" }}>
//                 <div className="row g-0">
//                   <div className="col-md-6 col-lg-5 d-none d-md-block">
//                     <img
//                       src={sin}
//                       alt="login form"
//                       className="img-fluid"
//                       style={{ borderRadius: "1rem 0 0 1rem" }}
//                     />
//                   </div>
//                   <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                     <div className="card-body p-4 p-lg-5 text-black">
//                       <form onSubmit={handleSubmit}>
//                         <div className="d-flex align-items-center mb-3 pb-1">
//                           <i
//                             className="fas fa-cubes fa-2x me-3"
//                             style={{ color: "#ff6219" }}
//                           />
//                           <span className="h2 fw-bold mb-0">Welcome back 👋</span>
//                         </div>
//                         <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>
//                           Sign into your account
//                         </h5>
//                         <div className="form-outline mb-4">
//                           <input
//                             type="email"
//                             id="form2Example17"
//                             className="form-control form-control-lg"
//                             name="email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                           />
//                           <label className="form-label" htmlFor="form2Example17">
//                             Email address
//                           </label>
//                         </div>
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="form2Example27"
//                             className="form-control form-control-lg"
//                             name="password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                           />
//                           <label className="form-label" htmlFor="form2Example27">
//                             Password
//                           </label>
//                         </div>

//                         {/* Error Message */}
//                         {errorMessage && (
//                           <div className="alert alert-danger" role="alert">
//                             {errorMessage}
//                           </div>
//                         )}

//                         <div className="pt-1 mb-4">
//                           <button className="btn btn-dark btn-lg btn-block" type="submit">
//                             Login
//                           </button>
//                         </div>

//                         <a className="small text-muted" href="#!" onClick={openModal}>
//                           Forgot password?
//                         </a>

//                         <Modal
//                           isOpen={modalIsOpen}
//                           onRequestClose={closeModal}
//                           contentLabel="Forgot Password Modal"
//                         >
//                           <ForgotPassword />
//                           <button onClick={closeModal}>Close</button>
//                         </Modal>

//                         <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
//                           Don't have an account?{" "}
//                           <Link to="/register" style={{ color: "#393f81" }}>
//                             Register Now
//                           </Link>
//                         </p>
//                         <a href="#!" className="small text-muted">
//                           Terms of use.
//                         </a>
//                         <a href="#!" className="small text-muted">
//                           Privacy policy
//                         </a>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <footer className="bg-light text-center text-lg-start">
//         <div className="text-center p-3" style={{ backgroundColor: '#7cb474' }}>
//           © Geoneer Geospatial Services
//           <a className="text-dark" href="https://geoneer.com.np/">
//             Geoneer
//           </a>
//         </div>
//       </footer>
//     </div>
//   )
// }

// export default Loginpage
