// import React, { useContext } from "react";
// import { useParams } from "react-router-dom";
// import AuthContext from "../context/AuthContext";

// const CourseHome = () => {
//   const { courseName } = useParams(); // Extract courseName from the URL
//   const { user } = useContext(AuthContext); // Access user data from AuthContext

//   // Log data for debugging
//   console.log("User:", user);
//   console.log("Course Data:", user.courseName);
//   console.log("Course Name from URL:", courseName);

//   // Handle loading state if user data isn't ready
//   if (!user) {
//     return <p>Loading user data...</p>;
//   }

//   // Normalize course names for comparison
//   const normalizedCourseName = courseName.replace(/-/g, " ").toLowerCase();
//   const normalizedCourseCode = user.course?.courseCode?.toLowerCase();

//   return (
//     <div className="course-home">
//       <h1>Welcome to the {courseName} Home Page</h1>

//       {!user.course ? (
//         <p>You are not enrolled in any course. Please check your account.</p>
//       ) : normalizedCourseCode === normalizedCourseName ? (
//         <>
//           <p>This is the homepage for your selected course:</p>
//           <h2>{user.course.courseName}</h2>
//           <p>Course Code: {user.course.courseCode}</p>
//           {/* Additional course details can go here */}
//         </>
//       ) : (
//         <p>No details available for this course. Please check your enrollment.</p>
//       )}
//     </div>
//   );
// };

// export default CourseHome;

import { useEffect, useState } from "react";
import './home.css';

const CoursePage = ({ match }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    // Retrieve user details from localStorage once (only on mount)
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      setUserDetails(JSON.parse(storedUserDetails));
    }
  }, []); // Empty dependency array means this effect only runs once on mount

  useEffect(() => {
    // If userDetails is available and courseName is part of the URL, fetch the course details
    if (userDetails && match.params.courseName) {
      // const courseName = match.params.courseName;
      // Fetch or process course details based on courseName (if needed)
      const course = userDetails.course; // Or fetch from API based on courseName
      setCourseDetails(course);
    }
  }, [userDetails, match.params.courseName]); // Dependencies: userDetails and courseName

  return (
    <div>
      {/* <h1>Course: {courseDetails ? courseDetails.courseName : "Loading..."}</h1> */}

      {/* Course Card */}
      <div className="course-card">
        {courseDetails ? (
          <>
            <img
              src={`https://nec.geoneer.com.np${courseDetails.coursePhoto}`}
              alt={courseDetails.courseName}
              className="course-card-photo"
            />
            <div className="course-card-info" >
              <div className="row">
                <div className="col-lg-12"><h2>{courseDetails.courseName}</h2></div>
              </div>
              <div className="row">
                <div className="col-lg-12"><p>{courseDetails.courseDescription}</p></div>
              </div>
              
              <div className="row">
                <div className="col-lg-12"><a href="/course-detail/"><button type="button" class="btn btn-success btn-sm">Explore Course</button></a>
                </div>
              </div>
              
            </div>
            
          </>
        ) : (
         
          <p>Loading course details...</p>
        )}
      </div>

      {/* Display userDetails */}
      {/* <div>
        <h2>User Details:</h2>
        {userDetails ? (
          <pre>{JSON.stringify(userDetails, null, 2)}</pre>
        ) : (
          <p>Loading user details...</p>
        )}
      </div> */}
    </div>
  );
};

export default CoursePage;




