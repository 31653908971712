// QRPopup.js
import React from 'react';
import qrCodeImage from './images/geoqr.jpeg'; // Add your QR code image here
import'./qr.css';

const QRPopup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Payment Instructions</h3>
        <p>Please scan QR code below to make the payment.</p>
        <p><bold>For Survey Officer (PSC) Preparation Rs. 2000</bold> <br/>
           <bold>For Geomatics Engineering NEC License Preparation Rs. 1000</bold>
        </p>
        <img src={qrCodeImage} alt="QR Code for payment" style={{ width: '300px', height: '300px' }} />
        <p>After payment, upload the payment screenshot using the form below.</p>
        <p>If your account is not activated in half an hour of the payment please contact at <b>9857885572,9862467445</b></p>
        <button className="btn btn-dark" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default QRPopup;
