
import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const ChapterTestResults = (props) => {
  const { authTokens, user } = useContext(AuthContext);
  const [quizResults, setQuizResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  const location = useLocation();
  // const subChapterId = location.state?.subChapterId;
  const chapterCode = location.state?.chapterCode;
  const subChapterCode = location.state?.subChapterCode;

  console.log(chapterCode)
  console.log(subChapterCode)
  // Fetch quiz results from the API
  useEffect(() => {
    const fetchQuizResults = async () => {
      try {
        const response = await axios.get(
          `https://nec.geoneer.com.np/api/exam/courses/SO-PSC/chapters/${chapterCode}/subchapters/${subChapterCode}/results/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens?.access}`,
            },
          }
        );
        setQuizResults(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching quiz results:", err);
        setError("Failed to load quiz results.");
        setLoading(false);
      }
    };
  
    if (authTokens && user) {
      fetchQuizResults();
    }
  }, [authTokens, user,chapterCode,subChapterCode]);
  

  if (loading) {
    return <p className="text-center">Loading quiz results...</p>;
  }

  if (error) {
    return <p className="text-center text-danger">{error}</p>;
  }

  return (
    <div className="container mt-4">
      <h3 className="text-center mb-4">Chapterwise Test Results</h3>
      <div className="table-responsive">
        <table className="table table-striped table-hover align-middle">
          <thead className="table-dark">
            <tr>
              <th scope="col">SN.</th>
              <th scope="col">Date/Time</th>
              <th scope="col">Chapter</th>
              {/* <th scope="col">Sub-Chapter</th> */}
              <th scope="col">Score</th>
              <th scope="col">Total Score</th>
            </tr>
          </thead>
          <tbody>
            {quizResults.length > 0 ? (
              quizResults.map((result, index) => (
                <tr key={result.id}>
                  <td>{index + 1}</td>
                  <td>{new Date(result.timestamp).toLocaleString()}</td>
                  <td>{result.chapter}</td>
                  {/* <td>{result.subChapter}</td> */}
                  <td>{result.score}</td>
                  <td>{result.total_score}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No quiz results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChapterTestResults;

