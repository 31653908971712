// import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

// const MockTestResults = () => {
//   return (
//     <div className="container mt-4">
//       <h3 className="text-center mb-4">Mock Test Results</h3>
//       <h5 className="text-center mb-4">Course: </h5>
//       <div className="table-responsive">
//         <table className="table table-striped table-hover align-middle">
//           <thead className="table-dark">
//             <tr>
//               <th scope="col">SN.</th>
//               <th scope="col">Date/Time</th>
//               <th scope="col">Score</th>
//               <th scope="col">Total Score</th>
//             </tr>
//           </thead>
//           <tbody>
//               <tr>
//                 <td>1</td>
//                 <td>2024-10-10</td>
//                 <td>67.5</td>
//                 <td>100</td>
//               </tr>
          
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default MockTestResults;


import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const MockTestResults = () => {
  const { authTokens, user } = useContext(AuthContext);
  const [quizResults, setQuizResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuizResults = async () => {
      try {
        const response = await axios.get(
          `https://nec.geoneer.com.np/api/exam/mocktest-results/5/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens?.access}`,
            },
          }
        );
        setQuizResults(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching quiz results:", err);
        setError("Failed to load mock test results.");
        setLoading(false);
      }
    };
  
    if (authTokens && user) {
      fetchQuizResults();
    }
  }, [authTokens, user]);
  

  if (!authTokens) {
    return <p>Please log in to view mock test results.</p>;
  }

  if (loading) {
    return <p>Loading mock test results...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-4">
      <h3 className="text-center mb-4">Mock Test Results</h3>
      <h5 className="text-center mb-4">Course: Survey Office PSC Preparation</h5>
      <div className="table-responsive">
        <table className="table table-striped table-hover align-middle">
          <thead className="table-dark">
            <tr>
              <th scope="col">SN.</th>
              <th scope="col">Date/Time</th>
              <th scope="col">Score</th>
              <th scope="col">Total Score</th>
            </tr>
          </thead>
          <tbody>
            {quizResults.length > 0 ? (
              quizResults.map((result, index) => (
                <tr key={result.id}>
                  <td>{index + 1}</td>
                  <td>{new Date(result.timestamp).toLocaleString()}</td>
                  <td>{result.score}</td>
                  <td>{result.total_score}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No results found for this mock test.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MockTestResults;

