// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import PrivateRoute from "./utils/PrivateRoute"
// import { AuthProvider } from './context/AuthContext'

// import Homepage from './views/Homepage'
// import Registerpage from './views/Registerpage'
// import Loginpage from './views/Loginpage'
// import Dashboard from './components/Dashboard'
// import Navbar from './views/Navbar'
// import Quiz from './components/Quiz'
// import RandomQuiz from './components/RandomQuiz'


// import Sallybus from './noticeandsallybus/Sallybus'
// import Chapter from './surveyofficer/Chapter';
// import ChapterCard from './surveyofficer/ChapterCard';




// function App() {
//   return (
//     <Router>
//       <AuthProvider>
//         < Navbar/>
 
//         <Switch>
          
//           <PrivateRoute component={Dashboard} path="/dashboard" exact /> 
//           <PrivateRoute component={RandomQuiz} path="/rquiz" exact /> 
//                  <PrivateRoute component={Quiz} path="/quiz" exact /> 
//       <PrivateRoute component={Sallybus} path="/sallybus" exact />
//             <Route component={Chapter} path="/chapter" exact />
//           <Route component={Loginpage} path="/login" />
//           <Route component={Registerpage} path="/register" exact />
//           <Route component={ChapterCard} path="/chapterlist" exact />
         
//           <Route component={Homepage} path="/" exact />
//         </Switch>
//       </AuthProvider>
//     </Router>
//   )
// }

// export default App


import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from "./utils/PrivateRoute";
// import { AuthProvider } from './context/AuthContext';
import { AuthProvider} from "./context/AuthContext";

import Homepage from './views/Homepage';
import Registerpage from './views/Registerpage';
import Loginpage from './views/Loginpage';
import Dashboard from './components/Dashboard';
import Navbar from './views/Navbar';
import Quiz from './components/Quiz';
import Chapter from './surveyofficer/Chapter';
import ChapterCard from './surveyofficer/ChapterCard';
import ChapterCardUn from './surveyofficer/ChapterCardUnverified';
import RandomQuiz from './components/RandomQuiz'
import CourseHome from "./views/CourseHome";
import Profile from "./views/Profile"
import CourseDetail from './views/Course-detail';
import SubChapter from './views/Sub-chapter';
import WeeklyResults from './views/results/WeeklyResults';
import MockTestResults from './views/results/MockTestResults';
import ChapterTestResults from './views/results/ChapterTestResults';
import ForgotPassword from './views/ForgetPasswordPage';
import UpdatePassword from './views/password/UpdatePassword';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import WeeklyTests from './views/Weekly-test';
import ChapterWiseExam from './views/exams/ChaperWiseExam';
import SubChapterWiseExam from './views/exams/SubChapterWiseExam';
import MockTestExam from './views/exams/MockTestExam';
import WeeklyExam from './views/exams/WeeklyExam';
import QuizResults from './views/results/QuizResults';
import SubChapterTestResult from './views/results/SubChapterTestResult';
import EveryWeeklyTestResult from './views/results/EveryWeeklyTestResult';
// import ValidityExpired from "./views/ValidityExpired"; // Add a validity-expired page



function App() {





  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <Switch>
          <PrivateRoute component={Dashboard} path="/dashboard" exact />
          <PrivateRoute component={Quiz} path="/quiz" exact />
         <PrivateRoute component={RandomQuiz} path="/rquiz" exact />
          {/* <PrivateRoute component={ChapterCard} path="/chapterlist" exact /> */}
          <Route component={Chapter} path="/chapter" exact />
          <Route component={ChapterCardUn} path="/cun" exact />
          <PrivateRoute component={Profile} path="/profile" exact />
          <PrivateRoute component={CourseDetail} path="/course-detail" exact />
          <PrivateRoute component={SubChapter} path="/subchapters" exact />
          <PrivateRoute component={WeeklyTests} path="/weekly-tests" exact />

          <PrivateRoute component={WeeklyResults} path="/weekly-test-results" exact />
          <PrivateRoute component={MockTestResults} path="/mock-test-results" exact />
          <PrivateRoute component={ChapterTestResults} path="/chapter-test-results" exact />
          <PrivateRoute component={SubChapterTestResult} path="/sub-chapter-test-results" exact />
          <PrivateRoute component={EveryWeeklyTestResult} path="/every-weekly-test-result" exact />



          <PrivateRoute component={ChapterWiseExam} path="/chapterwise-exam" exact />
          <PrivateRoute component={SubChapterWiseExam} path="/sub-chapterwise-exam" exact />
          <PrivateRoute component={MockTestExam} path="/mock-test" exact />
          <PrivateRoute component={WeeklyExam} path="/weekly-exam" exact />


          
          <PrivateRoute component={CourseHome} path="/course/:courseName"  />


          <Route component={Loginpage} path="/login" />
          <Route component={Registerpage} path="/register" exact />
          <PrivateRoute component={ForgotPassword} path="/forgot-password" exact />
          <PrivateRoute component={UpdatePassword} path="/update-password" exact />

          <PrivateRoute component={QuizResults} path="/qr" exact />
          
          <Route component={Homepage} path="/" exact />



   
            
              {/* <PrivateRoute component={Dashboard} path="/dashboard" exact /> */}
              <PrivateRoute component={ChapterCard} path="/chapterlist" exact />

       
        
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;



//above code is working
